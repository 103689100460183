<template>
    <div class="row p-0">
        <slot v-if="!isMobile">
            <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش سامانه تکنیکال در داشبورد</div>
                <div class="card">
                    <i class="uil uil-chart-line fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-chart-line icon-md"></i></p>
                        <h5 class="card-title ">سامانه تکنیکال</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'trading-view')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش نمودار در داشبورد</div>
                <div class="card">
                    <i class="uil uil-chart fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-chart icon-md"></i></p>
                        <h5 class="card-title ">نمودار</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'chart')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش جدول قیمت‌ها در داشبورد</div>
                <div class="card">
                    <i class="uil uil-list-ui-alt fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-list-ui-alt icon-md"></i></p>
                        <h5 class="card-title ">جدول قیمت‌ها</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'watchlist')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش جدیدترین اخبار در داشبورد</div>
                <div class="card">
                    <i class="uil uil-newspaper fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-newspaper icon-md"></i></p>
                        <h5 class="card-title ">اخبار</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'news')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش بوک‌مارک‌ها در داشبورد</div>
                <div class="card">
                    <i class="uil uil-bookmark-full fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-bookmark-full icon-md"></i></p>
                        <h5 class="card-title ">بوک‌مارک‌ها</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'favorite')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش یادداشت‌ها و اسناد در داشبورد</div>
                <div class="card">
                    <i class="uil uil-notes fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-notes icon-md"></i></p>
                        <h5 class="card-title ">یادداشت‌ها و اسناد</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'note')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش تقویم اقتصادی در داشبورد</div>
                <div class="card">
                    <i class="uil uil-calendar-alt fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-calendar-alt icon-md"></i></p>
                        <h5 class="card-title ">تقویم اقتصادی</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'calendar')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div> -->
            <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش وظایف روزانه در داشبورد</div>
                <div class="card">
                    <i class="uil uil-list-ol-alt fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-list-ol-alt icon-md"></i></p>
                        <h5 class="card-title ">وظایف روزانه</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'todo')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش پست‌های ارسال‌شده در داشبورد</div>
                <div class="card">
                    <i class="uil uil-file-graph fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-file-graph icon-md"></i></p>
                        <h5 class="card-title "> پست‌های ارسال‌شده</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'my-posts')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 modal-widget-col">
                <div class="Tooltips-box">ویجت نمایش ارسال‌های دوستان در داشبورد</div>
                <div class="card">
                    <i class="uil uil-file-graph fa-rotate-35"></i>
                    <div class="card-body text-center">
                        <p class="card-icon "><i class="uil uil-file-graph icon-md"></i></p>
                        <h5 class="card-title ">ارسال‌های دوستان</h5>
                        <a href="#" class="m-left tgju-btn tgju-btn-primary" v-tooltip.bottom="'افزودن به داشبورد'" @click.prevent="addWidget($event, 'friend-posts')"><i class="uil uil-plus-circle"></i> افزودن</a>
                    </div>
                </div>
            </div>
        </slot>
        <slot v-else>
            <ul class="widgets-list-mobile">  
                <li>
                    <i class="widgets-list-mobile-icon uil uil-chart-line icon-md"></i>
                    <h5 class="card-title ">سامانه تکنیکال</h5>
                    <div @click.prevent="addWidget($event, 'trading-view')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li>
                <li>
                    <i class="widgets-list-mobile-icon uil uil-chart icon-md"></i>
                    <h5 class="card-title ">نمودار</h5>
                    <div @click.prevent="addWidget($event, 'chart')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li>
                <li>
                    <i class="widgets-list-mobile-icon uil uil-list-ui-alt icon-md"></i>
                    <h5 class="card-title ">جدول قیمت‌ها</h5>
                    <div @click.prevent="addWidget($event, 'watchlist')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li>
                <li>
                    <i class="widgets-list-mobile-icon uil uil-newspaper icon-md"></i>
                    <h5 class="card-title ">اخبار</h5>
                    <div @click.prevent="addWidget($event, 'news')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li>
                <li>
                    <i class="widgets-list-mobile-icon uil uil-bookmark-full icon-md"></i>
                    <h5 class="card-title ">بوک‌مارک‌ها</h5>
                    <div @click.prevent="addWidget($event, 'favorite')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li>
                <li>
                    <i class="widgets-list-mobile-icon uil uil-notes icon-md"></i>
                    <h5 class="card-title ">یادداشت‌ها و اسناد</h5>
                    <div @click.prevent="addWidget($event, 'note')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li>
                <!-- <li>
                    <i class="widgets-list-mobile-icon uil uil-calendar-alt icon-md"></i>
                    <h5 class="card-title ">تقویم اقتصادی</h5>
                    <div @click.prevent="addWidget($event, 'calendar')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li> -->
                <li>
                    <i class="widgets-list-mobile-icon uil uil-list-ol-alt icon-md"></i>
                    <h5 class="card-title ">وظایف روزانه</h5>
                    <div @click.prevent="addWidget($event, 'todo')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li>
                <li>
                    <i class="widgets-list-mobile-icon uil uil-file-graph icon-md"></i>
                    <h5 class="card-title ">پست‌های ارسال‌شده</h5>
                    <div @click.prevent="addWidget($event, 'my-posts')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li>
                <li>
                    <i class="widgets-list-mobile-icon uil uil-file-graph icon-md"></i>
                    <h5 class="card-title ">ارسال‌های دوستان</h5>
                    <div @click.prevent="addWidget($event, 'friend-posts')" class="m-left tgju-btn tgju-btn-primary" ><i class="uil uil-plus-circle"></i> افزودن</div>
                </li>
            </ul>     
        </slot>
    </div>
</template>

<script>
// این کامپوننت مودال افزودن ویجت به داشبورد است
// لیست ویجت ها را به نمایش در می آورد
export default {
    name: 'WidgetsModal',
    components: {},
    data: function () {
        return {
            isMobile:false,
        }
    },
    mounted() {
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد وظیفه ارسال درخواست به پرنت جهت ایجاد ویجت مد نظر را دارد
        addWidget(event, type) {
            this.$parent.emitData('addWidget', { event, type });
        },
    },
}
</script>
